.form-box {
  width: 100%;
  margin: auto;
}
.form-box .notice /deep/ textarea {
  min-height: 150px !important;
}
 /deep/ .el-form--label-top .el-form-item__label {
  padding: 0;
}
