



































































































































































































































































































































































































































.form-box {
  width: 100%;
  margin: auto;

  .notice {
    /deep/ textarea {
      min-height: 150px !important;

  // .el-form-item {
  //   .el-input,.el-select {
  //     width: 300px;
  //   }
  //   .el-date-editor {
  //     width: 400px !important;
    }
  }
}
/deep/.el-form--label-top .el-form-item__label{
  padding: 0;
}
